import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from 'react-helmet'

import Layout from "../components/layout"
import SEO from "../components/seo"
import HtmlRenderer from "../components/htmlRenderer"

const TermsAndConditions = (props) => {
	const data = useStaticQuery(graphql`
		query Terms {
			dnhCms {
				pages(where: {pageTitle: "Terms and conditions", status: PUBLISHED}) {
					id
					bannerHeading
					pageTitle
					heading
					copy
					richCopy{
						html
					}
					pageBanner {
						url
					}
				}
			}
		}
	`)
	return(
		<>
			<Helmet
				bodyAttributes={{
					class: data.dnhCms.pages[0].pageTitle.replace(/\s+/g, '-').toLowerCase()
				}}
			/>
			<Layout location={props.location.pathname}
					bannerHeading={data.dnhCms.pages[0].bannerHeading}
					bannerUrl={data.dnhCms.pages[0].pageBanner.url}>
				<SEO title={data.dnhCms.pages[0].pageTitle} />
				<div className="container legal-section">
					<h2 className="page-headline">{data.dnhCms.pages[0].heading}</h2>
					<HtmlRenderer
						htmlData={data.dnhCms.pages[0].richCopy.html}
						rendererID="js-tos-renderer"
						rendererClass="legal-page-wrap" />
				</div>
			</Layout>
		</>
	)
}

TermsAndConditions.propTypes = {
	location: PropTypes.object
}

export default TermsAndConditions
